import * as React from "react"
import FullApp from "../../FullApp";
import Panel from "../../components/panel";
import Logout from "../../components/panel/pages/logout";
import Layout from "../../templates/layout";

export default (props) => (
  <FullApp>
    <Layout {...props} router={props} isDark={true}>
      <Panel {...props}>
        <Logout/>
      </Panel>
    </Layout>
  </FullApp>
);