import * as React from "react";
import {
  Button, Header, Container, Segment, Grid
} from "semantic-ui-react";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import {ILogout} from "../../../types";
import {navigateTo} from "../../../helpers/router";
import Cloud from "../../backgrounds/others/Cloud";

interface StateProps {
  logout: ILogout
}

export function mapStateToProps(state: any) {
  return {
    logout: state.logoutUser,
  };
}

interface DispatchProps {
  logoutUser: typeof actions.logoutUser
  getUser: typeof actions.fetchUser
}

const mapDispatchToProps = {
  logoutUser: actions.logoutUser,
  getUser: actions.fetchUser,
}

type Props = StateProps & DispatchProps;

class Logout extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {}
  }

  handleLogoutButtonClick(): void {
    if (typeof window !== `undefined`) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('surveyCode');
    }
    this.props.getUser();
    navigateTo('/');
  }

  render() {
    return (
      <Cloud>
        <Segment basic id="cloud-panel" style={{height: "calc(100vh - 10px)"}}>
          <Container id="cloud-container">
            <Grid stackable textAlign="center">
              <Grid.Column>
                <div>
                  <Header id="cloud-header">Wylogowanie</Header>
                  <Header id="cloud-subheader">Czy na pewno chcesz się wylogować?</Header>
                  <Button primary className='btn-logout-yes light-shadow' onClick={this.handleLogoutButtonClick.bind(this)}>Tak</Button>
                  <Button secondary className='btn-logout-yes dark-shadow' onClick={()=> navigateTo('/')}>Nie</Button>
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </Segment>
      </Cloud>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);